<template>
    <v-card hover class="pl-5 pr-5">
        <v-card-title class="mb-0 pb-0" style="border-bottom:2px solid #A0A0A0;">
            <v-row class="align-center" no-gutters>
                <v-col class="text-left">공지사항 : {{ notice.notice_title }}</v-col>
                <v-col cols="1" class="text-right pt-0 mr-n3" @click="closePopup">
                    <v-btn icon>
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
        </v-card-title>
        <v-card-text class="pt-0">
            <v-row no-gutters class="mt-5">
                <v-col class="body-1" v-html="noticeBody"></v-col>
            </v-row>
        </v-card-text>
        <v-divider class="mt-3 mb-1"></v-divider>
        <v-card-actions>
            <v-row no-gutters class="align-baseline">
                <v-col class="text-left">
                    <v-checkbox
                        v-model="dontShowToday"
                        label="오늘 더 이상 보지 않기"
                        dense
                    ></v-checkbox>
                </v-col>
                <v-col class="text-right">
                    <v-btn color="success" v-on:click="closePopup">OK</v-btn>
                </v-col>
            </v-row>
        </v-card-actions>
    </v-card>

</template>

<script>
import {mapState} from 'vuex'

export default {
    props: {},
    data: () => ({
        dontShowToday: false,
    }),
    computed: {
        ...mapState('login', {
            notice: state => state.notice,
        }),
        noticeBody() {
            let txt = ''
            if (this.notice.notice_body) {
                txt = this.notice.notice_body.split('\n').join('<br/>')
            }
            return txt
        }
    },

    mounted() {
        //this.$store.commit('login/noticeAlertFlag', true)
    },
    methods: {
        closePopup() {
            this.$emit('cancel', {'dont_show_today': this.dontShowToday})
        },
    }
}
</script>
